import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Schedule', href: '/schedule', current: false },
  { name: 'Gallery', href: '/gallery/2024', current: false },
  { name: 'Waiver', href: '/waiver', current: false },
  { name: 'Support', href: 'mailto:info@fiveraysllc.com', current: false },
]

const gallerySubItems = [
  { name: '2023', href: '/gallery/2023', current: false },
  { name: '2024', href: '/gallery/2024', current: true },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
  const [openDropdown, setOpenDropdown] = useState(false)

  return (
    <Disclosure as="nav" class="bg-cust-gray-dark">
      {({ open }) => (
        <>
          <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div class="relative flex h-16 items-center justify-between">
              <div class="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span class="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon class="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon class="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div class="absolute right-0 flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div class="hidden sm:ml-6 sm:block">
                  <div class="flex space-x-4">
                    {navigation.map((item) => {
                      if (item.name === 'Gallery') {
                        return (
                          <div
                            key={item.name}
                            onMouseEnter={() => setOpenDropdown(true)}
                            onMouseLeave={() => setOpenDropdown(false)}
                            class="relative"
                          >
                            <a
                              href={item.href}
                              class={classNames(
                                item.current
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'rounded-md px-3 py-2 text-sm font-medium flex items-center'
                              )}
                            >
                              {item.name}
                            </a>
                            {openDropdown && (
                              <div class="absolute top-full left-0 bg-cust-gray-dark rounded-md shadow-lg">
                                {gallerySubItems.map((subItem) => (
                                  <a
                                    key={subItem.name}
                                    href={subItem.href}
                                    class="block px-3 py-2 text-gray-300 hover:bg-gray-700 hover:text-white"
                                  >
                                    {subItem.name}
                                  </a>
                                ))}
                              </div>
                            )}
                          </div>
                        )
                      } else {
                        return (
                          <a
                            key={item.name}
                            href={item.href}
                            class={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel class="sm:hidden">
            <div class="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => {
                if (item.name === 'Gallery') {
                  return (
                    <div key={item.name}>
                      <Disclosure.Button
                        as="a"
                        href={item.href}
                        class={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block rounded-md px-3 py-2 text-base font-medium'
                        )}
                      >
                        {item.name}
                      </Disclosure.Button>
                      {openDropdown && (
                        <div class="mt-2">
                          {gallerySubItems.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as="a"
                              href={subItem.href}
                              class={classNames(
                                'block rounded-md px-3 py-2 text-gray-300 hover:bg-gray-700 hover:text-white'
                              )}
                            >
                              {subItem.name}
                            </Disclosure.Button>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                } else {
                  return (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      class={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
                }
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
