
const faqs = [
    {
        "question": "What are the playing conditions for the tournament ?",
        "answer": `
            <p>All games will be played in the 50B format, which is a miniature version of the 100B format. You can read more about the <a href='https://shorturl.at/nuJX2' class="text-blue-500 hover:text-blue-700"> draft playing conditions here.</a></p>
        `
    },
    {
        "question": "What ball will be used ?",
        "answer": `
            <p> The tournament will be played with <a href="https://en.wikipedia.org/wiki/Tape_ball" class="text-blue-500 hover:text-blue-700">Tape ball</a></p>
        `
    },
    {
        "question": "What are the locations of indoor fields ?",
        "answer": "<p>The tournament will be hosted at Tyngsboro Sports Center</p>"
    },
    // {
    //     "question": "What are the responsibilities of volunteers ?",
    //     "answer": `<p> Volunteers will serve as match officials for a minimum of six games throughout the tournament. In this capacity, they will assist in preparing
    //      the field, and take on the responsibilities of both umpire and scorer during the games.
    //     </p>`
    // },


]




const Faqs = () => {
    return (
        <div class="bg-cust-gray px-6 lg:px-8">
            {/* <div class="px-36"> */}
            <h1 class='flex font-medium justify-center text-2xl font-semibold text-center capitalize lg:text-3xl text-white pt-8'>FAQs</h1>
            <div class="mx-auto mt-8 grid max-w-xl divide-y divide-cust-gray-xlight">
            {faqs.map((faq) => (
                <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span class='text-white'>{faq.question}</span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="white" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn  mt-3 text-gray-300" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>

                </details>
            </div>

            ))}

            </div>
            <div class='pb-8'></div>
            </div>

    )

}


export default Faqs;
