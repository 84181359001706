import React, { useState} from "react"
import axios from 'axios';
import sampleImage from "./../assets/fiverays_zelle.jpeg";

const ZelleQR = ({ buttonText }) => {

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
    <div>
    <p
        onClick={openModal}
        class="text-blue-500 hover:text-blue-700 font-bold"
    >
        {buttonText}
    </p>

    {isOpen && (
        <div class="fixed inset-0 flex items-center justify-center z-50">
        <div class="modal-overlay"></div>

        <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div class="modal-content py-4 text-left px-6">
            <div>
                {/* <h1 className="text-2xl font-bold mb-4">Zelle</h1> */}
                <img
                src={sampleImage}
                alt="Zelle QR code"
                className="mb-4"
                />
                <p class="flex justify-center">Zelle Id : mohan@fiveraysllc.com</p>
            </div>
            </div>

            <div class="modal-footer py-4 px-6 flex justify-center">
            <button
                onClick={closeModal}
                class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
                Close
            </button>
            </div>
        </div>
        </div>
    )}
    </div>
);
};



const ContactDetails = ({ prefix, onChange }) => {
    return (
            <>
            <div>
                <label for={`${prefix}-first-name`} class="block text-sm font-semibold leading-6 text-white">First name</label>
                <div class="mt-2.5">
                    <input onChange={onChange} type="text" name={`${prefix}-first-name`} id={`${prefix}-first-name`} autocomplete="given-name" required={true} class="block w-full bg-cust-gray-light rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-cust-gray-xlight placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:cust-orange-light sm:text-sm sm:leading-6"></input>
                </div>
            </div>
            <div>
                <label for={`${prefix}-last-name`} class="block text-sm font-semibold leading-6 text-white">Last name</label>
                <div class="mt-2.5">
                    <input onChange={onChange} type="text" name={`${prefix}-last-name`} id={`${prefix}-last-name`} autocomplete="family-name" required={true} class="block w-full bg-cust-gray-light rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-cust-gray-xlight placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:cust-orange-light sm:text-sm sm:leading-6"></input>
                </div>
            </div>
            <div class="sm:col-span-2">
                <label for={`${prefix}-email`} class="block text-sm font-semibold leading-6 text-white">Email</label>
                <div class="mt-2.5">
                <input onChange={onChange} type="email" name={`${prefix}-email`} id={`${prefix}-email`} autocomplete="email" required={true} class="block w-full bg-cust-gray-light rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-cust-gray-xlight placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:cust-orange-light sm:text-sm sm:leading-6"></input>
                </div>
            </div>
            <div class="sm:col-span-2">
                <label for={`${prefix}-phone-number`} class="block text-sm font-semibold leading-6 text-white">Phone number</label>
                <div class="relative mt-2.5">
                <div class="absolute inset-y-0 left-0 flex items-center">
                    <label for="country" class="sr-only">Country</label>
                    <select id="country" name="country" class="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:cust-orange-light sm:text-sm">
                    <option>US</option>
                    </select>
                    <svg class="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                    </svg>
                </div>
                <input onChange={onChange} type="tel" name={`${prefix}-phone-number`} id={`${prefix}-phone-number`} required={true} autocomplete="tel" class="block w-full bg-cust-gray-light rounded-md border-0 px-3.5 py-2 pl-20 text-white shadow-sm ring-1 ring-inset ring-cust-gray-xlight placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:cust-orange-light sm:text-sm sm:leading-6"></input>
                </div>
            </div>
            </>
        )
    }

const SuccessComponent = () => {
    return (
        <div class="bg-cust-gray grow min-h-screen">
          <div class="align-middle text-center text-white py-96">
              <h2>Registration Sucessful!</h2>
              <p>We'll get back to you soon.</p>
          </div>
        </div>
    );
}

const FailureComponent = () => {
    return (
        <div class="bg-cust-gray grow min-h-screen">
            <div class="align-middle text-center text-white py-96">
                <h2>Submission failed!</h2>
                <p>If the issue persists, please contact info@fiveraysllc.com.</p>
            </div>
        </div>
    );
}



const PlayerForm = () => {
    const [formData, setFormData] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Custom URL and headers
        const apiUrl = 'https://forms.mmuppidi.com/team/registration';
        const customHeaders = {
            'Content-Type': 'application/json',
            'x-api-key': '5MkbhMGpme3hVtAusLaKx3On6MV7qbrm4o9ygvcW'
        };
        try {
            await axios.post(
                apiUrl,
                {
                    "data": formData,
                    "sheet": "player_registrations"
                },
                { headers: customHeaders }
            );
          setSubmissionStatus('success');
        } catch (error) {
          setSubmissionStatus('failure');
        }
      };

      if (submissionStatus === 'success') {
        return (
          SuccessComponent()
        );
      }

      if (submissionStatus === 'failure') {
        return (
          FailureComponent()
        );
      }

      return (
        <div class="isolate bg-cust-gray px-6 pt-20 pb-72 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Player Pass Registration</h2>
                <p class="mt-2 text-lg leading-8 text-white">Fill to form to start your registration.</p>
            </div>
            <form onSubmit={handleSubmit} class="mx-auto mt-6 max-w-xl sm:mt-6">
                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <ContactDetails prefix="player" onChange={handleChange}></ContactDetails>
                    <div class="sm:col-span-2">
                        <label class="text-sm leading-6 text-white" id="switch-1-label">
                            Your registration will only be finalized once payment has been processed through Zelle. By clicking the submit button, you acknowledge your commitment to abide by the <a class="text-blue-500 hover:text-blue-700" href='https://drive.google.com/file/d/191fPK8nkLRHHmEgRMXCO3_sgPnA7TzGD/view?usp=share_link'>playing conditions</a> and
                            <a class="text-blue-500 hover:text-blue-700" href="https://drive.google.com/file/d/1ni7BFqe24KTkUnLKWNCR8q9nKDgTe0Gh/view?usp=share_link"> code of conduct</a>. Furthermore, you recognize that the registration process will not be considered complete until the <a class="text-blue-500 hover:text-blue-700" href="https://drive.google.com/file/d/1_Kb_vUk2wfqN30EX-JRunLYuOL0sw5n2/view?usp=share_link">waiver and release form</a> has been signed by all team members.
                        </label>
                    </div>
                </div>

                <div class="mt-10">
                    <button type="submit" class="block w-full rounded-md bg-cust-orange px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-cust-orange-xlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cust-orange-light">Submit</button>
                </div>

            </form>
            <div class="flex py-4 justify-center">
                <ZelleQR buttonText="Click here for Zelle information"></ZelleQR>
            </div>
        </div>
      );

}



const TeamForm = () => {
    const [formData, setFormData] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Custom URL and headers
        const apiUrl = 'https://forms.mmuppidi.com/team/registration';
        const customHeaders = {
            'Content-Type': 'application/json',
            'x-api-key': '5MkbhMGpme3hVtAusLaKx3On6MV7qbrm4o9ygvcW'
        };
        try {
            await axios.post(
                apiUrl,
                {
                    "data": formData,
                    "sheet": "team_registrations"
                },
                { headers: customHeaders }
            );
          setSubmissionStatus('success');
        } catch (error) {
          setSubmissionStatus('failure');
        }
      };

      if (submissionStatus === 'success') {
        return (
          SuccessComponent()
        );
      }

      if (submissionStatus === 'failure') {
        return (
          FailureComponent()
        );
      }

      return (
        <div class="isolate bg-cust-gray px-6 pt-8 pb-4 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Team Pass Registration</h2>
                <p class="mt-2 text-lg leading-8 text-white">Fill the form to start your registration.</p>
            </div>
            <form onSubmit={handleSubmit} class="mx-auto mt-6 max-w-xl sm:mt-6">
                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div class="sm:col-span-2">
                        <label for="team-name" class="block text-m font-bold leading-6 text-white">Team Name</label>
                        <div class="mt-2.5">
                            <input type="text" name="team-name" onChange={handleChange} id="company" required={true} autocomplete="organization" class="block w-full bg-cust-gray-light rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-cust-gray-xlight placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:cust-orange-light sm:text-sm sm:leading-6"></input>
                        </div>
                    </div>
                    <div class="sm:col-span-2">
                        <label for="first-name" class="block text-m font-bold leading-6 text-white">Primary Contact</label>
                    </div>
                    <ContactDetails prefix="primary" onChange={handleChange}></ContactDetails>
                    <div class="sm:col-span-2">
                        <label for="first-name" class="block text-m font-bold leading-6 text-white">Secondary Contact</label>
                    </div>
                    <ContactDetails prefix="secondary" onChange={handleChange}></ContactDetails>
                    <div class="sm:col-span-2">
                        <label class="text-sm leading-6 text-white" id="switch-1-label">
                            Your registration will only be finalized once payment has been processed through Zelle. By clicking the submit button, you acknowledge your commitment to abide by the <a class="text-blue-500 hover:text-blue-700" href='https://drive.google.com/file/d/191fPK8nkLRHHmEgRMXCO3_sgPnA7TzGD/view?usp=share_link'>playing conditions</a> and
                            <a class="text-blue-500 hover:text-blue-700" href="https://drive.google.com/file/d/1ni7BFqe24KTkUnLKWNCR8q9nKDgTe0Gh/view?usp=share_link"> code of conduct</a>. Furthermore, you recognize that the registration process will not be considered complete until the <a class="text-blue-500 hover:text-blue-700" href="https://drive.google.com/file/d/1_Kb_vUk2wfqN30EX-JRunLYuOL0sw5n2/view?usp=share_link">waiver and release form</a> has been signed by all team members.
                        </label>
                    </div>
                </div>

                <div class="mt-10">
                    <button type="submit" class="block w-full rounded-md bg-cust-orange px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-cust-orange-xlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cust-orange-light">Submit</button>
                </div>

            </form>
            <div class="flex py-4 justify-center">
                <ZelleQR buttonText="Click here for Zelle information"></ZelleQR>
            </div>
        </div>
      );

}


const PlayerRegistrationForm = () => {
    const [formData, setFormData] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Custom URL and headers
        const apiUrl = 'https://forms.mmuppidi.com/team/registration';
        const customHeaders = {
            'Content-Type': 'application/json',
            'x-api-key': '5MkbhMGpme3hVtAusLaKx3On6MV7qbrm4o9ygvcW'
        };
        try {
            await axios.post(
                apiUrl,
                {
                    "data": formData,
                    "sheet": "player_registrations_actual"
                },
                { headers: customHeaders }
            );
          setSubmissionStatus('success');
        } catch (error) {
          setSubmissionStatus('failure');
        }
      };

      if (submissionStatus === 'success') {
        return (
          SuccessComponent()
        );
      }

      if (submissionStatus === 'failure') {
        return (
          FailureComponent()
        );
      }

      return (
        <div class="isolate bg-cust-gray px-6 pt-20 pb-72 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Player Registration</h2>
                <p class="mt-2 text-lg leading-8 text-white">Fill the form to start your registration.</p>
            </div>
            <form onSubmit={handleSubmit} class="mx-auto mt-6 max-w-xl sm:mt-6">
                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <ContactDetails prefix="player" onChange={handleChange}></ContactDetails>
                    <div class="sm:col-span-2">
                        <label for={`player-team-name`} class="block text-sm font-semibold leading-6 text-white">Team name</label>
                        <div class="mt-2.5">
                        <input onChange={handleChange} type="text" name={`player-team-name`} id={`player-team-name`} autocomplete="email" required={true} class="block w-full bg-cust-gray-light rounded-md border-0 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-cust-gray-xlight placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:cust-orange-light sm:text-sm sm:leading-6"></input>
                        </div>
                    </div>
                    <div class="sm:col-span-2">
                        <label class="text-sm leading-6 text-white" id="switch-1-label">
                            By clicking the submit button, you acknowledge your commitment to abide by the <a class="text-blue-500 hover:text-blue-700" href='https://drive.google.com/file/d/191fPK8nkLRHHmEgRMXCO3_sgPnA7TzGD/view?usp=share_link'>playing conditions</a> and
                            <a class="text-blue-500 hover:text-blue-700" href="https://drive.google.com/file/d/1ni7BFqe24KTkUnLKWNCR8q9nKDgTe0Gh/view?usp=sharing"> code of conduct</a>. Furthermore, you recognize that the registration process will not be considered complete until the <a class="text-blue-500 hover:text-blue-700" href="/waiver">waiver and release form</a> has been signed.
                        </label>
                    </div>
                </div>


                <div class="mt-10">
                    <button type="submit" class="block w-full rounded-md bg-cust-orange px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-cust-orange-xlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cust-orange-light">Submit</button>
                </div>

            </form>
        </div>
      );

}

export { TeamForm, PlayerRegistrationForm};
export default PlayerForm;
