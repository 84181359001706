import React, {useEffect, useState} from "react"

import axios from 'axios';


const SuccessComponent = () => {
    return (
        <div>
            <div class="bg-emerald-100 border border-emerald-400 text-emerald-700 px-4 py-3 rounded relative" role="alert">
                <div class="flex flex-row justify-center">
                    <svg class="w-6 h-6 text-emerald fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                    </svg>
                    <strong class="font-bold px-4">Your account was subscribed  !!</strong>
                </div>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg class="fill-current h-6 w-6 text-emerald-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
            </div>
        </div>
    )
}


const FailureComponent = () => {
    return (
        <div>
        <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <div class="flex flex-row justify-center">
        <svg class="w-6 h-6 text-red fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
        </svg>
        <strong class="font-bold px-4">Subscription failed ! Please contact info@fiveraysllc.com if issue persists.</strong>
        </div>
        {/* <span class="block sm:inline">Something seriously bad happened.</span> */}
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
        </div>
        </div>
    )
}



const SubscribeComponent = () => {

    const [formData, setFormData] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Custom URL and headers
        const apiUrl = 'https://forms.mmuppidi.com/team/registration';
        const customHeaders = {
            'Content-Type': 'application/json',
            'x-api-key': '5MkbhMGpme3hVtAusLaKx3On6MV7qbrm4o9ygvcW'
        };
        try {
            await axios.post(
                apiUrl,
                {
                    "data": formData,
                    "sheet": "subscriptions"
                },
                { headers: customHeaders }
            );
          setSubmissionStatus('success');
        } catch (error) {
          console.log(error)
          setSubmissionStatus('failure');
        }
      };

      if (submissionStatus === 'success') {
        return (
          SuccessComponent()
        );
      }

      if (submissionStatus === 'failure') {
        return (
          FailureComponent()
        );
      }

    return (
        <div class="bg-cust-gray">
            <div class="container px-6 py-10 mx-auto">
            <h2 class="text-2xl font-medium tracking-tight text-white sm:text-3xl">Subscribe to our newsletter.</h2>
            <p class="mt-4 text-md leading-8 text-gray-300">Stay updated! Join the Super Cricket League newsletter for exclusive news, scores, and behind-the-scenes action. Subscribe now!</p>

            <form onSubmit={handleSubmit}>
                <div class="mt-6 flex max-w-md gap-x-4">
                <label for="email-address" class="sr-only">Email address</label>
                <input id="email-address" onChange={handleChange} name="email" type="email" autocomplete="email" required class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-cust-orange sm:text-sm sm:leading-6" placeholder="Enter your email"/>
                <button type="submit" class="flex-none rounded-md bg-cust-orange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-cust-orange-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cust-orange">Subscribe</button>
                </div>
            </form>

            </div>
        </div>
    );
}


export default SubscribeComponent;
