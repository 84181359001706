import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Indoor cricket league',
    description:
      'Experience non-stop cricket action at an indoor sports complex, ensuring your game continues in all weather conditions!',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Standard indoor soccer fields',
    description:
      'Experience cricket like never before on 100\' to 200\' turf fields, perfectly illuminated for our premier night matches.',
    icon: LockClosedIcon,
  },
  {
    name: 'Pitch',
    description:
      'Play effortlessly on our 66\' standard cricket pitch, ensuring your bowling action stays consistent and match-ready.',
    icon: ArrowPathIcon,
  },

]

// export default function Features() {
//   return (
//     <section class="bg-cust-gray">
//     <div class="container px-6 py-12 mx-auto">
//         <div class="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
//             {features.map((feature) => (
//                 <div>
//                     <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white">{feature.name}</h1>
//                     <p class="mt-2 text-gray-500 dark:text-gray-400">{feature.description}</p>
//                 </div>
//             ))}

//         </div>
//     </div>
// </section>
//   )
// }



export default function Features(){
    return (
        <section class="bg-cust-gray-dark">
            <div class="container px-6 py-10 mx-auto">
                <h1 class="text-2xl font-semibold text-center capitalize lg:text-3xl text-white">League Highlights</h1>

                <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
                    {features.map((feature) => (

                        <div class="flex flex-col items-center p-6 space-y-3 text-center bg-cust-gray rounded-xl ">
                            {/* <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                </svg>
                            </span> */}
                            <h1 class="text-xl font-semibold capitalize text-white">{feature.name}</h1>
                            <p class="text-gray-300">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

// {features.map((feature) => (
//     <div key={feature.name} className="relative pl-16">
//       <dt className="text-base font-semibold leading-7 text-gray-900">
//         <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
//           <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
//         </div>
//         {feature.name}
//       </dt>
//       <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
//     </div>
//   ))}
