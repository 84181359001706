
import UiFooter from '../components/custom_footer';
import NavBar from '../components/navbar'
import Carousel from '../components/carousel';
import Album from '../components/albums';

const image_paths = [
    "images/2023/6.jpg",
    "images/2023/1.jpg",
    "images/2023/2.jpg",
    "images/2023/4.jpg",
    "images/2023/3.jpg",
    "images/2023/5.jpg",
    "images/2023/7.jpg",
    "images/2023/8.jpg",
    "images/2023/9.jpg",
    "images/2023/10.jpg",
    "images/2023/11.jpg",
    "images/2023/12.jpg",
    "images/2023/12.jpg",
    "images/2023/14.jpg",
    "images/2023/15.jpg",
    "images/2023/16.jpg",
    "images/2023/17.jpg",
    "images/2023/18.jpg",
    "images/2023/19.jpg",
    "images/2023/20.jpg",
    "images/2023/21.jpg",
    "images/2023/22.jpg",
    "images/2023/4.jpg",
    "images/2023/12.jpg",
]


const image_paths_2024 = [
    "images/2024/0.jpg",
    "images/2024/1.jpg",
    "images/2024/2.jpg",
    "images/2024/4.jpg",
    "images/2024/3.jpg",
    "images/2024/5.jpg",
    "images/2024/7.jpg",
    "images/2024/8.jpg",
    "images/2024/9.jpg",
    "images/2024/10.jpg",
    "images/2024/11.jpg",
    "images/2024/12.jpg",
    "images/2024/12.jpg",
    "images/2024/14.jpg",
    "images/2024/15.jpg",
    "images/2024/16.jpg",
    "images/2024/17.jpg",
    "images/2024/18.jpg",
    "images/2024/19.jpg",
    "images/2024/20.jpg",
    "images/2024/21.jpg",
    "images/2024/22.jpg",
    "images/2024/23.jpg",
    "images/2024/24.jpg",
    "images/2024/25.jpg",
    "images/2024/26.jpg",
    "images/2024/27.jpg",
    "images/2024/28.jpg",
    "images/2024/29.jpg",
    "images/2024/30.jpg",
    "images/2024/31.jpg",
    "images/2024/32.jpg",
    "images/2024/33.jpg",
    "images/2024/34.jpg",
    "images/2024/35.jpg",
    "images/2024/36.jpg",
    "images/2024/37.jpg",
    "images/2024/38.jpg",
    "images/2024/39.jpg",
    "images/2024/40.jpg",


]

const Gallery2023 = () => {
    return (
        <div>

    <NavBar></NavBar>
    <Album images={image_paths} title={"2023 Winter League"}></Album>
    {/* <Carousel images={image_paths}></Carousel> */}

    <UiFooter></UiFooter>
</div>

    )
}


const Gallery2024 = () =>  {
    return (
        <div>

    <NavBar></NavBar>
    <Album images={image_paths_2024} title={"Super Cricket League - 2024"}></Album>
    {/* <Carousel images={image_paths}></Carousel> */}

    <UiFooter></UiFooter>
</div>

    )
}

export {Gallery2023, Gallery2024}
