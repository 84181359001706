import Main from '../components/main';
import UiFooter from '../components/custom_footer';
import NavBar from '../components/navbar'

export default function Home() {
    return (
        <div>
            <NavBar></NavBar>
            <Main></Main>
            <UiFooter></UiFooter>
        </div>
    )
}