
const leaderboard = [
    { position: '1', name: 'Bulls', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
    { position: '2', name: 'Blue Bees', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
    { position: '3', name: 'Team A', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
    { position: '4', name: 'Team B', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
    { position: '5', name: 'Team C', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
    { position: '6', name: 'Team D', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
    { position: '7', name: 'Team E', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
    { position: '8', name: 'Team F', played: '0', won: '0', lost: '0',  net_rr: '0', points: '0'},
  ]

const LeaderBoard = () => {

    return (
    <div class="container px-6 py-8 mx-auto">
    <div class="xl:items-center xl:-mx-8 xl:flex">
        <div class="flex flex-col items-center xl:items-start xl:mx-8">
            <h1 class="text-2xl font-medium text-gray-800 capitalize lg:text-3xl dark:text-white">Points Table</h1>

            <div class="mt-4">
                <span class="inline-block w-40 h-1 bg-cust-orange rounded-full"></span>
                <span class="inline-block w-3 h-1 mx-1 bg-cust-orange rounded-full"></span>
                <span class="inline-block w-1 h-1 bg-cust-orange rounded-full"></span>
            </div>

            <p class="mt-4 font-medium text-gray-500 dark:text-gray-300 hidden md:block">
                SCL 50B (2024) LEAGUE STAGE
            </p>
        </div>
        <div class="flex-1 xl:mx-8">
                <div class="bg-cust-gray pt-2 justify-end max-w-2xl mx-auto rounded-lg">
                    <div class="text-center mb-4 font-semibold text-white">SCL 50B (2024) LEAGUE STAGE</div>
                    <table class="w-full">
                        <thead class="border-b border-cust-orange">
                            <tr>
                                <th class="text-center md:py-2 md:px-4 text-white">#</th>
                                <th class="text-left md:py-2 md:px-4 text-white">TEAM</th>
                                <th class="text-center md:py-2 md:px-4 text-white">MAT</th>
                                <th class="text-center md:py-2 md:px-4 text-white">WIN</th>
                                <th class="text-center md:py-2 md:px-4 text-white">LOST</th>
                                <th class="text-center md:py-2 md:px-4 text-white">N/R</th>
                                <th class="text-center md:py-2 md:px-4 text-white">POINTS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaderboard.map((item) => (
                                    <tr>
                                    <td class="text-center md:py-2 md:px-4 text-white">{item.position}</td>
                                    <td class="text-center md:py-2 md:px-4 flex items-center text-white">
                                    {item.name}
                                    </td>
                                    <td class="text-center md:py-2 md:px-4 text-white">{item.played}</td>
                                    <td class="text-center md:py-2 md:px-4 text-white">{item.won}</td>
                                    <td class="text-center md:py-2 md:px-4 text-white">{item.lost}</td>
                                    <td class="text-center md:py-2 md:px-4 text-white">{item.net_rr}</td>
                                    <td class="text-center md:py-2 md:px-4 text-white">{item.points}</td>
                                </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                {/* <div class="text-center mt-4">
                    <button class="text-blue-500 hover:underline">VIEW FULL TABLE</button>
                </div> */}
            </div>
            </div>
            </div>
    )
}


export default LeaderBoard;
