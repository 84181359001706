import UiFooter from '../components/custom_footer';
import NavBar from '../components/navbar'

import PlayerForm from '../components/registration'


export default function PlayerRegistration() {
    return (
        <div>
            <NavBar></NavBar>
            <PlayerForm></PlayerForm>
            <UiFooter></UiFooter>
        </div>
    )
}
