// import LogoComponent from './logo';
import Pricing from './pricing';
import Features from "./features";
import SubscribeComponent from "./subscribe";
import Numbers from './numbers';

// import { ReactComponent as Logo } from './../assets/logo.svg'


export default function Main() {
  return (
    <div className="overflow-hidden bg-gradient-to-r from-cust-gray via-cust-gray-light to-cust-gray">
      <div class="container justify-center flex flex-col px-6 py-4 mx-auto space-x-2 space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center lg:space-x-16">
        <div class="flex items-center justify-center max-w-md lg:w-1/2">
            {/* <LogoComponent></LogoComponent> */}
            <img src="logo_mdpi.png" alt="" class="transform scale-75 lg:scale-100" ></img>
        </div>
        <div class="flex flex-col items-center max-w-xlg lg:flex-row lg:w-1/2">
            <div class="max-w-lg lg:mx-12 lg:order-2">
                <h1 class="font-['Helvetica'] text-2xl font-semibold tracking-wide text-white lg:text-4xl">Super Cricket League</h1>
                <p class="mt-4 text-gray-300">Join Super Cricket League (Boston), where our collective passion for cricket unites us! Engage in thrilling matches, build lasting relationships, and enhance your fitness, all while immersing yourself in the exhilarating world of cricket.</p>
                <p class="mt-4 text-gray-300">2025 season starts Jan 8th at Tyngsboro Sports Center. Get over the icy hump day slump
                -- hit a 'six' at our winter cricket tournament! </p>
            </div>
        </div>
    </div>

      <Features></Features>
      <Numbers></Numbers>
      {/* <div class="bg-cust-gray py-12 sm:py-12">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h1 class="text-2xl font-semibold text-center capitalize lg:text-3xl text-white">2024 Season In Numbers</h1>
      <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
        <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt class="text-base leading-7 text-white">Sixes</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">151</dd>
          </div>
          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt class="text-base leading-7 text-white">Fours</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">140</dd>
          </div>
          <div class="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt class="text-base leading-7 text-white">Wickets</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">266</dd>
          </div>
        </dl>
        </div>
      </div>
    </div> */}
      <Pricing></Pricing>
      <SubscribeComponent></SubscribeComponent>

    </div>
  )
}
