import React from 'react';


const Album = ({ images, title }) => {
  return (
    <div class="bg-cust-gray">
      <h1 class="flex font-medium text-white justify-center text-center mb-4 font-semibold pt-8">
        {title}
      </h1>
      <div class="container mx-auto p-4">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div
              key={index}
              class="overflow-hidden rounded-lg bg-gray-100 relative aspect-w-1 aspect-h-1"
            >
              <img
                src={image}
                alt={`Gallery ${index}`}
                class="object-cover w-full h-full"
                // style={{ aspectRatio: image.aspectRatio || "1/1" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default Album;
