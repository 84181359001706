

const features = [
  {
    name: 'Sixes',
    description:
      '151'
  },
  {
    name: 'Fours',
    description:
      '140'
  },
  {
    name: 'Wickets',
    description:
      '266'
  },
  {
    name: 'Run Outs',
    description:
      '116'
  },
  {
    name: 'Ducks',
    description:
      '40'
  },

]




export default function Numbers(){
    return (
        <section class="bg-cust-gray">
            <div class="container px-6 py-10 mx-auto">
                <h1 class="text-2xl font-semibold text-center capitalize lg:text-3xl text-white">SCL 2024 in Numbers</h1>

                <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-5">
                    {features.map((feature) => (

                        <div class="flex flex-col items-center p-6 space-y-3 text-center bg-cust-gray-dark rounded-xl ">
                            {/* <span class="inline-block p-3 text-blue-500 bg-blue-100 rounded-full dark:text-white dark:bg-blue-500">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                                </svg>
                            </span> */}
                            <h1 class="text-xl font-semibold capitalize text-white">{feature.name}</h1>
                            <p class="text-gray-300">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}


