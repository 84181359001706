import UiFooter from '../components/custom_footer';
import NavBar from '../components/navbar'
import {TeamForm} from '../components/registration'

export default function TeamRegistration() {
    return (
        <div>
            <NavBar></NavBar>
            <div className="bg-cust-gray">
                <TeamForm></TeamForm>
            </div>
            <UiFooter></UiFooter>
        </div>
    )
}
