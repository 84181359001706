


const Grounds = () => {
    return (
<div class="bg-cust-gray-light px-6 lg:px-8">
    <h1 class="flex font-medium justify-center text-2xl font-semibold text-center capitalize lg:text-3xl text-white pt-8">
        Tyngsboro Sports Center
    </h1>
    <div class=" flex justify-center grid grid-cols-2 sm:grid-cols-4 gap-12 py-2 sm:py-8">

        <div>
            <img
                class="mx-auto h-auto max-w-full rounded-lg"
                src="https://assets-global.website-files.com/6287a60491203231163514f3/644835969c61c8a1d01332e1_Turf%20Use-57-p-1600.jpg"
                alt="Image 2"
            />
            {/* <!-- <p class="flex text-white justify-center text-center">Westford</p> --> */}
        </div>
    </div>
</div>
    )
};


export default Grounds;
