
import UiFooter from '../components/custom_footer';
import NavBar from '../components/navbar'

const attributions = [
    {
        "name": "stadium by Gofficon ",
        "link": "https://thenounproject.com/browse/icons/term/stadium/",
        "title":"stadium Icons",
        "provider" : "Noun Project",
        "license": "CC BY 3.0"
    },
    {
        "name": "stadium by Gofficon ",
        "link": "https://thenounproject.com/browse/icons/term/stadium/",
        "title":"stadium Icons",
        "provider" : "Noun Project",
        "license": "CC BY 3.0"
    }
]

export default function Credits() {
    return (
        <div>
            <NavBar></NavBar>
            <div className="overflow-hidden bg-cust-gray">
                <div class="container justify-center flex flex-col px-6 py-4 mx-auto space-x-2 space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center lg:space-x-16">
                {/* <div class="flex items-center gap-x-3">
                    <h2 class="text-lg font-medium text-gray-800 dark:text-white">Credits</h2>
                </div> */}
                <div class="flex flex-col mt-6">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                    <thead class="bg-gray-50 dark:bg-gray-800">
                                    
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                    
                                        {attributions.map((attribution) => (
                                            <tr>
                                            <td class="px-4 py-4 text-sm font-medium text-gray-700 whitespace-nowrap">
                                            <div class="inline-flex items-center gap-x-3">
                                                

                                                <div class="flex items-center gap-x-2">
                                                    
                                                    <div>
                                                        <p>{attribution.credit}</p>
                                                        <p>{attribution.name} from <a href={attribution.link} title={attribution.title}>{attribution.provider}</a> ({attribution.license})</p>
                                                    </div>
                                                </div>
                                            </div>
                                            </td> </tr>
                                         ))}

                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UiFooter></UiFooter>
        </div>
    )
}