
// https://merakiui.com/components/marketing/pricing

export default function Pricing() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div class="bg-cust-gray-dark">
    <div class="container px-6 py-8 mx-auto">
        <div class="xl:items-center xl:-mx-8 xl:flex">
            <div class="flex flex-col items-center xl:items-start xl:mx-8">
                <h1 class="text-2xl font-medium capitalize lg:text-3xl text-white">Ways to play</h1>

                <div class="mt-4">
                    <span class="inline-block w-40 h-1 bg-cust-orange rounded-full"></span>
                    <span class="inline-block w-3 h-1 mx-1 bg-cust-orange rounded-full"></span>
                    <span class="inline-block w-1 h-1 bg-cust-orange rounded-full"></span>
                </div>

                <p class="mt-4 font-medium text-gray-300">
                    You can get started by selecting your pass!
                </p>

                {/* <a href="#" class="flex items-center mt-4 -mx-1 text-sm text-gray-700 capitalize dark:text-blue-400 hover:underline hover:text-blue-600 dark:hover:text-blue-500">
                    <span class="mx-1">read more</span>
                    <svg class="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a> */}
            </div>

            <div class="flex-1 xl:mx-8">
                <div class="mt-8 space-y-8 md:-mx-4 md:flex md:items-center md:justify-center md:space-y-0 xl:mt-0">
                    <div class="max-w-sm mx-auto border rounded-lg md:mx-4 border-gray-700">
                        <div class="p-6">
                            <h1 class="text-xl font-medium capitalize lg:text-2xl text-white">Team Pass</h1>

                            <p class="mt-4 text-gray-300">
                                Register your team with up to 15 players and guarantee at least 7 exciting league games!
                            </p>

                            <h2 class="mt-4 text-2xl font-semibold  sm:text-3xl text-gray-300">$1325</h2>

                            <p class="mt-1 text-gray-300">
                                Winter Season
                            </p>

                            <button class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-cust-orange rounded-md hover:bg-cust-orange-light focus:outline-none focus:bg-cust-orange-light focus:ring focus:ring-cust-orange-xlight focus:ring-opacity-80" onClick={() => openInNewTab("/team-registration")}>
                                Start Now
                            </button>
                        </div>

                        {/* <hr class="border-gray-200 dark:border-gray-700"> */}

                        <div class="p-6">
                            <h1 class="text-lg font-medium  capitalize lg:text-xl text-white">What’s included:</h1>

                            <div class="mt-8 space-y-4">
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-cust-orange-light" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-300">Register upto 15 players</span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-cust-orange-light" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-300">7+ games</span>
                                </div>
                                {/* <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-cust-orange-light" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-300">Volunteers get 30% discount (~ $30)</span>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div class="max-w-sm mx-auto border rounded-lg md:mx-4 border-gray-700">
                        <div class="p-6">
                            <h1 class="text-xl font-medium capitalize lg:text-2xl text-white">Player Pass</h1>

                            <p class="mt-4 text-gray-300">
                                Sign up solo; we'll find you a team for you or we refund your fee!
                            </p>

                            <h2 class="mt-4 text-2xl font-semibold sm:text-3xl text-gray-300">$150</h2>

                            <p class="mt-1 text-gray-300">
                                Winter Season
                            </p>

                            <button class="w-full px-4 py-2 mt-6 tracking-wide text-white capitalize transition-colors duration-300 transform bg-cust-orange rounded-md hover:bg-cust-orange-light focus:outline-none focus:bg-cust-orange-light focus:ring focus:ring-cust-orange-xlight focus:ring-opacity-80" onClick={() => openInNewTab("/player-registration")}>
                                Start Now
                            </button>
                        </div>

                        {/* <hr class="border-gray-200 dark:border-gray-700"> */}

                        <div class="p-6">
                            <h1 class="text-lg font-medium capitalize lg:text-xl text-white">What’s included:</h1>

                            <div class="mt-8 space-y-4">
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-cust-orange-light" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-300">Single player registration</span>
                                </div>

                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-cust-orange-light" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-300">7+ games</span>
                                </div>
                                {/* <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-cust-orange-light" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                    </svg>

                                    <span class="mx-4 text-gray-300">Volunteers get 30% discount (~ $30)</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
